import React from 'react';
import './Card.css';

const Card = ({ card }) => {

    return (
        <div className='card'>
            {
                card.suit === 'JOKER' ?
                    <img src={`/assets/cards/back/back.png`} />
                    :
                    <img src={`/assets/cards/front/${card.value}-${card.suit.toUpperCase()}.png`} />
            }
        </div>
    )
}

export default Card