import React, { useEffect, useState } from 'react'
import {
    motion,
    useMotionValue,
    useTransform,
    AnimatePresence
} from "framer-motion";
import './MotionCard.css'
import { width } from '@mui/system';
import useWindowDimensions from '../Utils/Utils';

const MotionCard = ({ card, setExitX, setIndex, newCardShown, discardCard, index, drag, initial, animate, transition, exitX }) => {
    const [flipped, setFlipped] = useState(false);
    const x = useMotionValue(0);
    const scale = useTransform(x, [-150, 0, 150], [0.5, 1, 0.5]);
    const rotate = useTransform(x, [-150, 0, 150], [-45, 0, 45], {
        clamp: false
    });

    const { height, width } = useWindowDimensions();

    function handleDragEnd(event, info) {
        if (info.offset.x < -50 || info.velocity.x < 0) {
            setExitX(-350);
            discardCard(card);
            setIndex(index + 1);
        }
        if (info.offset.x > 50 || info.velocity.x > 0) {
            setExitX(350);
            setIndex(index + 1);
            discardCard(card);
        }

    }

    function onAnimationUpdate(latest) {
        if (!flipped && latest.rotateY >= 80 && latest.rotateY <= 100) {
            console.log("Anim update")
            setFlipped(true);
            newCardShown();
        }
    }

    return (
        <motion.div
            className="flip-card"
            style={{
                position: "absolute",
                top: 0,
                x: x,
                rotate: rotate,
                cursor: "grab"
            }}
            whileTap={{ cursor: "grabbing" }}
            drag={(card && card.value === "JOKER") || flipped ? 'x' : false}
            dragConstraints={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }}
            onDragEnd={handleDragEnd}
            initial={initial}
            animate={animate}
            onUpdate={onAnimationUpdate}
            transition={transition}
            exit={{
                x: exitX,
                opacity: 0,
                scale: 0.5,
                transition: { duration: 0.2 }
            }}

        >
            <motion.div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: 30,
                    scale: scale
                }}
                className="flip-card-inner"

            >
                {
                    flipped ? <div className='card-back'>{card ? <div className='card-graphic'>{card.img}</div> : <div>Back</div>}</div> : <div className='card-graphic card-front'><img src={'/assets/cards/back/back.png'} /></div>
                }
            </motion.div>
        </motion.div>
    );
}

export default MotionCard