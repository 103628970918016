import React, { useEffect, useState } from 'react'
import {
    motion,
    useMotionValue,
    useTransform,
    AnimatePresence
} from "framer-motion";
import Card from './Card';
import MotionCard from './MotionCard';
import './Deck.css';
import DiscardedCards from './DiscardedCards';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { Rules } from '../Rules';
import useWindowDimensions, { shuffle, useInterval } from '../Utils/Utils';

const Deck = () => {
    const [cards, setCards] = useState([]);
    const [index, setIndex] = useState(0);
    const [exitX, setExitX] = useState("100%");
    const [discardedCards, setDiscardedCards] = useState([])
    const [drawerState, setDrawerState] = useState(false)
    const [cardTitle, setCardTitle] = useState(Rules.JOKER.title)
    const [cardExplanation, setCardExplanation] = useState(Rules.JOKER.explanation);
    const [kingsCount, setKingsCount] = useState(0);
    const [kingsCup, setKingsCup] = useState(false);
    const [fire, setFire] = useState(0);
    const [fireworksX, setFireworksX] = useState(0);
    const [fireworksY, setFireworksY] = useState(0);
    const [kingActive, setKingActive] = useState(false);
    const x = useMotionValue(0);
    const { height, width } = useWindowDimensions();
    const rotate = useTransform(x, [-150, 0, 150], [-45, 0, 45], {
        clamp: false
    });

    useEffect(() => {
        if (cards.length === 0)
            populateCards();
    }, [])

    useEffect(() => {
        if (kingsCount === 4) {
            setKingsCup(true);
        }
    }, [kingsCount])

    useEffect(() => {
        if (cards.length > 0 && cards[index].value !== "K") {
            setKingActive(false);
        }
    }, [index])


    useInterval(() => {
        if (kingsCup) {
            setFireworksX((prevFX) => ((prevFX * 0) + Math.floor(Math.random() * width)));
            setFireworksY((prevFY) => Math.floor(Math.random() * height));
            setFire((prevFire) => prevFire + 1);
        }
    }, 1000);

    function populateCards() {
        var deck = [];
        var cardVals = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K']
        // var cardVals = ['A', '10', 'J', 'Q', 'K']
        var cardSuits = ['Spades', 'Clubs', 'Diamonds', 'Hearts']
        var deckIndex = 1;

        cardSuits.forEach(suit => {
            cardVals.forEach(val => {
                const src = `/assets/cards/front/${val}-${suit.toUpperCase()}.png`;
                const image = new Image();
                image.src = src;
                const newCard = {
                    id: deckIndex,
                    value: val,
                    suit: suit,
                    img: <img src={src} />,
                }

                deck.push(newCard);
                deckIndex++;
            })
        });

        shuffle(deck);

        deck.unshift(
            {
                id: 0,
                value: 'JOKER',
                suit: 'JOKER',
                img: <img src='/assets/cards/back/back.png' />
            }
        )

        setCards(deck);
        //setDiscardedCards(deck);
    }

    function discardCard(card) {
        setDiscardedCards([...discardedCards, card]);
    }

    function toggleDrawer(state) {
        setDrawerState(state);
    }

    function newCardShown() {
        setCardTitle(Rules[cards[index].value].title);
        setCardExplanation(Rules[cards[index].value].explanation);

        if (index > 0) {
            if (cards[index].value === "K") {
                console.log(`${kingsCount + 1} King(s) drawn`);
                setKingsCount(kingsCount + 1);
                setKingActive(true);
            }
        }
    }

    return (
        <div className='game-container'>
            {
                kingActive &&
                <div className={`kings-warning-container ${kingsCount === 4 ? 'kings-cup' : ''}`}>
                    {
                        kingsCount === 4 ?
                            `Kings Cup` :
                            `${4 - kingsCount} King${4 - kingsCount === 1 ? '' : 's'} remaining`
                    }
                </div>
            }
            <ReactCanvasConfetti
                particleCount={400}
                startVelocity={width > 900 ? 60 : 40}
                spread={360}
                gravity={0.4}
                ticks={300}
                fire={kingsCount}
                className="confetti-container" />

            <ReactCanvasConfetti
                particleCount={400}
                spread={360}
                startVelocity={width > 900 ? 60 : 40}
                gravity={0.4}
                ticks={200}
                origin={{ x: fireworksX / width, y: fireworksY / height }}
                fire={fire}
                className="confetti-container" />

            {
                cards.length > 0 &&
                <div>
                    <div className='card-info-container'>
                        <div className='card-info-title'>
                            {cardTitle}
                        </div>
                        <div className='card-info-explanation'>
                            {cardExplanation}
                        </div>
                    </div>
                    <div className='deck-container'>
                        <motion.div
                            className='deck'
                            style={{
                                position: "relative"
                            }}
                        >
                            <AnimatePresence initial={false}>
                                <MotionCard
                                    key={index + 1}
                                    initial={{ scale: 0, y: 255, opacity: 0 }}
                                    animate={{ scale: .85, y: 50, opacity: 0.5 }}
                                    transition={{
                                        scale: { duration: 0.2 },
                                        opacity: { duration: 0.4 }
                                    }}
                                />
                                <MotionCard
                                    key={index}
                                    card={cards[index]}
                                    setExitX={setExitX}
                                    setIndex={setIndex}
                                    discardCard={discardCard}
                                    newCardShown={newCardShown}
                                    index={index}
                                    drag="x"
                                    animate={{ scale: 1, y: 0, opacity: 1, rotateY: 180 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 20,
                                        opacity: { duration: 0.2 },
                                        rotateY: { delay: 0.5, duration: 0.5 }
                                    }}
                                    exitX={exitX}
                                />
                            </AnimatePresence>
                        </motion.div>
                    </div>
                </div>
            }
            <div className='options'>
                <Button className={'view-cards'} onClick={() => toggleDrawer(true)} variant="contained">View Cards</Button>
                <Drawer
                    className='discarded-cards-drawer'
                    anchor={'bottom'}
                    open={drawerState}
                    onClose={() => (toggleDrawer(false))}
                    height={200}
                >
                    <DiscardedCards toggleDrawer={() => toggleDrawer(false)} cards={discardedCards} />
                </Drawer>
            </div>
        </div>
    )
}

export default Deck