import './App.css';
import Deck from './Objects/Deck';

function App() {
  return (
    <div className="App">
      <Deck />
    </div>
  );
}

export default App;
