export const Rules =
{
    "JOKER": {
        "title": "Let's get started",
        "explanation": "Swipe the card to begin."
    },
    "A": {
        "title": "Waterfall",
        "explanation": "Everyone starts drinking and they can only stop when the person to their right has stopped drinking. Starting from the person who drew this card."
    },
    "2": {
        "title": "You",
        "explanation": "The person who drew this card selects a person to drink."
    },
    "3": {
        "title": "Me",
        "explanation": "The person who drew this card drinks."
    },
    "4": {
        "title": "Floor",
        "explanation": "The last person to touch the floor drinks."
    },
    "5": {
        "title": "Guys",
        "explanation": "All the guys drink."
    },
    "6": {
        "title": "Chicks",
        "explanation": "All the girls drink."
    },
    "7": {
        "title": "Heaven",
        "explanation": "The last person to put their hands up drinks."
    },
    "8": {
        "title": "Mate",
        "explanation": "You pick a mate, for the rest of the game, when either of the mates drink, the other has to as well."
    },
    "9": {
        "title": "Rhyme",
        "explanation": "Pick a word, the person to your right has to say another word that rhymes with it. This continues until someone repeats a word or fails to say a word."
    },
    "10": {
        "title": "Category",
        "explanation": "Pick a category, the person to your right has to say another word that relates to that category. This continues until someone repeats a word or fails to say a word."
    },
    "J": {
        "title": "Never Have I Ever",
        "explanation": "Say something you haven't done but think other people have done. All those who have not done that must drink."
    },
    "Q": {
        "title": "Rule",
        "explanation": "You must create a rule that everyone has to follow. If someone breaks the rule, they must drink. This continues until the next Queen or the game ends."
    },
    "K": {
        "title": "Kings Cup",
        "explanation": "For the first 3 Kings drawn, pour a drink of your choice into the King's Cup. When the last King is drawn, that person must drink the King's Cup."
    }
}
