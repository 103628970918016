import React, { useEffect, useState } from 'react'
import Card from './Card'
import GridLayout from 'react-grid-layout';
import './DiscardedCards.css'
import { Button } from '@mui/material';

const DiscardedCards = ({ cards, toggleDrawer }) => {
    return (
        <div className='discarded-cards-drawer-inner'>
            <div className='cards-left-container'>
                {
                    `${cards.length <= 1 ? 52 : 53 - cards.length} / 52 cards remaining`
                }
            </div>
            <div className='discarded-cards-container'>
                {
                    cards.map((card, index) => (
                        <Card key={index} card={card} />
                    ))
                }
            </div>
            <div className='close-button-container'>
                <Button variant="contained" onClick={toggleDrawer}>Close</Button>
            </div>
        </div>
    );
}

export default DiscardedCards